<template>
    <div></div>
</template>

<script>
export default {
    IsFreePage: true,
    beforeMount() {
        if (!!location.hash && location.hash.length > 1) {
            localStorage.setItem(
                "ecosystem_target",
                location.hash.substring(1)
            );
            this.$router.push("/dang-nhap");
        } else {
            this.$router.push("/");
        }
    },
};
</script>

<style>
</style>